import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import serverDown from "../images/serverdown.svg"
import "./404.scss"

const errorTitle = "Oops!"
const errorMessage =
    "We can't find the page you're looking for -- unless you were looking for this page, in which case congrats! You found it."
const buttonLabel = "Go back to Home"

export default function Error() {
    return (
        <Layout>
            <div className="error">
                <div className="error__title">{errorTitle}</div>
                <div className="error__message">{errorMessage}</div>
                <img
                    className="error__illustration"
                    src={serverDown}
                    alt="illustration of server maintenance"
                />
                <Link className="error__home-button" to="/">
                    {buttonLabel}
                </Link>
            </div>
        </Layout>
    )
}
